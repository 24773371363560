<template>
  <div
    class="content"
    :class="{ 'content--extended': expansion }"
  >
    <div
      v-if="create_title"
      class="content__title"
      :class="{ 'content__title--margin': viewing_liked_products }"
    >
      <h1
        v-if="!should_class_be_removed_for_animation_effect"
        class="content__title__animation"
      >
        <router-link
          :to="`/Products/${router_params.category}`"
          itemprop="category"
          :aria-label="`Go to category ${$route.params.category}`"
        >
          {{ capitalize_word(user_viewing_entity === product && !$route.params.subcategory ? first_product_title : $route.params.category) }}
        </router-link>
        <span v-if="$route.params.subcategory && !expansion"> - <b>{{ capitalize_word($route.params.subcategory) }}</b></span>
        <router-link
          v-else-if="$route.params.subcategory && $route.params.product && expansion"
          :to="`/Products/${router_params.category || router_params.subcategory}/${router_params.category ? router_params.subcategory : ''}`"
          itemprop="subcategory"
          :aria-label="`Go to subcategory ${$route.params.subcategory}`"
        >
          - {{ capitalize_word($route.params.subcategory) }}
        </router-link>
      </h1>
    </div>
    <h2
      v-if="viewing_liked_products && !liked_products.length"
      class="title"
    >{{ translations.add_liked }}</h2>
    <h2
      v-if="!viewing_liked_products && !assemble_entities && !loading_products"
      class="title"
    >{{ translations.products_missing }}</h2>
    <div class="content__products" v-if="viewing_liked_products && liked_products.length">
      <Product
        v-for="(product, index) in get_liked_products"
        :key="`product-${index}`"
        :product_data="product"
        :loading="!product.id"
      />
    </div>
    <div v-else-if="assemble_entities">
      <div v-if="user_viewing_entity === parent" class="content__collections">
        <router-link
          v-for="({ title, handle, image }, index) in assemble_entities.collections"
          :key="`collection-${handle}-${index}`"
          :to="`/Products/${assemble_entities.handle}/${handle}`"
          itemprop="link"
          class="content__collection"
        >
          <div class="content__collection-image">
            <img v-if="image" :src="image">
          </div>
          <div>{{ title }}</div>
        </router-link>
      </div>
      <div class="content__products" v-else-if="user_viewing_entity === collection">
        <filter_bar
          :products="assemble_entities"
          @sort_by_price="direction => sort(direction, 'price')"
          @sort_alphabetically="direction => sort(direction, 'alphabeticaly')"
          @filter="updated_filters => filters = updated_filters"
        />
        <Product
          v-for="(product, index) in get_filtered_products"
          :key="`product-${index}`"
          :product_data="product"
          :loading="!product.id"
        />
      </div>
      <div v-else-if="user_viewing_entity === product">
        <Product :product_data="get_filtered_products[0] || {}" :expand="true" :loading="!(get_filtered_products[0] || {}).id"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import html_entity_encoder from "urlencode"
import Product from "./Product"
import capitalize_word from "../../methods/capitalize_word"
import filter_bar from "../../../Shared/components/utils/filter_bar"
import { CLIENT_STORE } from "../../constants/other"
import get_curly_brace_content from "../../../Shared/methods/get_curly_brace_content"
import { content_pages } from "../../../Shared/constants/other"
import { ARE_ANY_PRODUCTS_BEING_FETCHED, FETCH_PRODUCTS_BY_IDS, SET_LIKED_PRODUCTS, UPDATE_PRODUCT_BEING_VIEWED } from "../../stores/Client/constants"
import { user_liked_products } from "../../../../data/other_constants"
import filter_out_unavailable_products from "../../methods/filter_out_unavailable_products"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"
import { event_hub } from "../../../../main"

export default {
  components: {
    Product,
    filter_bar
  },
  data() {
    return {
      user_viewing_entity: undefined,
      should_class_be_removed_for_animation_effect: false,
      expansion: false,
      loading_products: true,
      opened_product_types: [],
      filters: [],
      parent: "parent",
      collection: "collection",
      product: "product",
      sort_price: null,
      sort_alphabetically: null,
      content_pages
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "products",
      "liked_products",
      "translations",
      "collections",
      "selected_language"
    ]),
    ...mapGetters(CLIENT_STORE, {
      still_fetching_products: ARE_ANY_PRODUCTS_BEING_FETCHED
    }),
    router_params() {
      const encode_category = Boolean(
        (this.$route.params.category && this.user_viewing_entity !== this.product) ||
        (this.$route.params.category && this.$route.params.subcategory)
      )
      const encode_subcategory = Boolean(
        (this.$route.params.category && this.$route.params.subcategory && this.user_viewing_entity !== this.product) ||
        (this.$route.params.category && this.$route.params.subcategory && this.$route.params.product)
      )

      return {
        category: encode_category ? html_entity_encoder(this.$route.params.category || "") : this.$route.params.category,
        subcategory: encode_subcategory ? html_entity_encoder(this.$route.params.subcategory || "") : this.$route.params.subcategory,
        product: this.$route.params.product || "" // Because using ID
      }
    },
    viewing_liked_products() {
      return this.$route.params.category === content_pages.LIKED
    },
    get_liked_products() {
      return this.products?.filter(({ id }) => this.liked_products?.some(product_id => product_id === id))
    },
    create_title() {
      const category = this.router_params.category
      const subcategory = this.router_params.subcategory

      return subcategory
        ? `
        <a href="/Products/${category}" itemprop="category" :aria-label="Go to category ${category}">
          <b>${this.capitalize_word(
            category
          )}</b></a> - ${this.capitalize_word(subcategory)}`
        : category ? this.capitalize_word(category) : ""
    },
    parent_collections() {
      if (this.collections.length) {
        return this.collections
          ?.filter(({ body_html }) => body_html && get_curly_brace_content(body_html)[1])
          ?.map(({ body_html }) => ({
            handle: get_curly_brace_content(body_html)[1],
            collections: this.collections.filter(
              ({ body_html: parent }) => parent && get_curly_brace_content(parent)[1] === get_curly_brace_content(body_html)[1]
            )
          }))
          ?.reduce(
            (total, current) => total.some(({ handle }) => handle === current.handle) ? total : [...total, current]
          , [])
      }

      return []
    },
    get_product_ids() {
      const { category, subcategory, product } = this.router_params

      if (this.user_viewing_entity === this.collection) {
        if (subcategory) return (
          (
            (
              (this.parent_collections.find(({ handle }) => handle === category) || {}).collections || []
            ).find(({ handle }) => handle === subcategory) || {}
          )?.products || []
        )?.map(({ id }) => id)
        else return (this.collections.find(
          ({ handle, body_html }) => handle === category && !body_html) || {}
        )?.products?.map(({ id }) => id)
      }

      if (category && subcategory && product) {
        const correct_parent = this.parent_collections.find(({ handle }) => handle === category)
        const correct_collection = ((correct_parent || {}).collections || []).find(({ handle }) => handle === subcategory)
        const product_ids = correct_collection ? correct_collection.products.map(({ id }) => id) : []

        // Check that product exists in collection
        return product_ids.includes(product) ? [product] : []
      }

      if (subcategory) {
        const correct_collection = this.collections.find(({ handle, body_html }) => handle === category && !body_html)

        // Check that both collection and product exists in collection
        return (correct_collection && correct_collection.products.some(({ id }) => id === subcategory)) ? [subcategory] : []
      }

      return [category]
    },
    missing_products() {
      if ([this.collection, this.product]?.includes(this.user_viewing_entity)) {
        return this.get_product_ids?.filter(id => !this.products?.find(({ id: product_id }) => product_id === id))
      }

      return []
    },
    assemble_entities() {
      const { category } = this.router_params

      if (this.user_viewing_entity === this.parent) return this.parent_collections.find(
        ({ handle }) => handle === category
      )

      return [
        ...(this.products || [])?.filter(
          product => this.get_product_ids?.find(id => product?.id === id) // Products that are already fetched
        ),
        ...(this.still_fetching_products ? this.missing_products?.map(_ => ({})) : []) // Products that have not yet been fetched
      ]
    },
    get_filtered_products() {
      return this.assemble_entities
        ?.filter(this.apply_filters)
        ?.sort((a, b) => {
          if (this.sort_alphabetically === null) return
          const title_a = get_correct_translation_value(a, "title", [this.selected_language])
          const title_b = get_correct_translation_value(b, "title", [this.selected_language])

          if (title_a < title_b) return this.sort_alphabetically ? 1 : -1
          if (title_a > title_b) return this.sort_alphabetically ? -1 : 1
          return 0
        })
        ?.sort((a, b) => {
          if (this.sort_price === null) return

          const price_a = Number(get_correct_translation_value(a.variants[0], "price", [this.selected_language], false))
          const price_b = Number(get_correct_translation_value(b.variants[0], "price", [this.selected_language], false))

          return this.sort_price ? (price_a - price_b) : (price_b - price_a)
        })
    },
    first_product_title() {
      if (!this.get_filtered_products[0]) return

      return get_correct_translation_value(this.get_filtered_products[0], "title", [this.selected_language], false)
    }
  },
  watch: {
    $route() {
      this.assign_entity_being_viewed()
      this.load_products()
    },
    viewing_liked_products() {
      this.set_liked(true)
    }
  },
  created() {
    this.assign_entity_being_viewed()
    event_hub.$on("expand_product", (handle, type) => {
      this.opened_product_types = type // TODO add product types to enable similar products

      if (handle === "") this.expansion = false
      else this.expansion = true
    })
  },
  mounted() {
    this.load_products()
    this.set_liked()
  },
  methods: {
    ...mapActions(CLIENT_STORE, {
      fetch_products: FETCH_PRODUCTS_BY_IDS
    }),
    ...mapMutations(CLIENT_STORE, {
      update_product_being_viewed: UPDATE_PRODUCT_BEING_VIEWED,
      set_liked_products: SET_LIKED_PRODUCTS
    }),
    capitalize_word,
    set_liked(should_fetch = false) {
      const liked_products = JSON.parse(
        window.localStorage.getItem(user_liked_products)
      ) || []

      this.set_liked_products(liked_products)

      if (should_fetch) this.fetch_products({ ids: liked_products }).then(
        () => this.set_liked_products(filter_out_unavailable_products(liked_products)
      ))
    },
    async load_products() {
      this.loading_products = true
      await this.fetch_products({ ids: this.missing_products })

      this.$nextTick(() => this.loading_products = false)
    },
    sort(value, name) {
      this.sort_price = null
      this.sort_alphabetically = null
      this[`sort_${name}`] = value
    },
    apply_filters({ options }) {
      if (!this.filters.length || !options) return true

      return this.filters.reduce(
        (final_decision, { name, value }) =>
          final_decision ?
            options.find((option) =>
              get_correct_translation_value(option, "name", [this.selected_language]) === name &&
              get_correct_translation_value(option, "values", [this.selected_language]).includes(value)
            ) : false
        , true)
    },
    /*
     * 
     */
    assign_entity_being_viewed() {
      this.user_viewing_entity = undefined
      let { category, subcategory, product } = this.router_params

      const is_category_parent_collection = this.parent_collections.find(({ handle }) => category === handle)
      const is_category_collection = this.collections.find(({ handle }) => category === handle)
      const is_category_product = this.products.find(({ id }) => category === id)

      if (category && subcategory && product) this.user_viewing_entity = this.product
      else if (category && subcategory && !product) {
        if (is_category_parent_collection) {
          this.user_viewing_entity = this.collection
          this.expansion = false
        }
        else {
          this.expansion = true
          this.update_product_being_viewed(this.expansion)
          this.user_viewing_entity = this.product
        }
      }
      else if (category && !subcategory) {
        if (is_category_parent_collection) {
          this.user_viewing_entity = this.parent
        }
        else if (is_category_collection) {
          this.user_viewing_entity = this.collection
        }
        else if (is_category_product) {
          this.user_viewing_entity = this.product
        }
        this.expansion = false
      }
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_marketplace-constants" as *;

  .content {
    margin-bottom: 100px;

    &--extended {
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      background-color: var(--background_light);

      @media (max-width: $tablet) {
        width: calc(100% + 20px);
        margin: 0 -10px 100px;
        padding: 0 10px;
      }
    }

    &__title {
      position: relative;
      padding: $spacing--small 0;
      border-bottom: 1px solid var(--background_heavy);
      background-color: var(--body_color);

      @media (max-width: $tablet) {
        padding: $spacing--small;
        width: calc(100% + 20px);
        transform: translateX(-10px);
      }

      &--margin {
        margin-bottom: $spacing--small;

        @media (max-width: $tablet--small) {
          margin-bottom: $spacing--tiny;
        }
      }

      @media (max-width: $tablet--small) {
        padding: $spacing--tiny;
      }

      &__animation {
        width: 100%;
        margin: 0;
        color: var(--background_heavy);
        line-height: 20px;
        margin: 0;
        font-size: 16px;
        text-align: left;
        animation: slide-text-in 2s ease-out;

        a {
          color: var(--background_heavy);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__collection {
      position: relative;
      overflow: hidden;
      color: var(--font_light);
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      vertical-align: bottom;
      background-color: var(--body_color);
      transition: transform 250ms linear, box-shadow 250ms linear;
      border-radius: $border-radius;

      &s {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
        margin-top: 20px;

        @media (max-width: $tablet) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: $tablet--small) {
          grid-template-columns: 1fr 1fr;
          column-gap: 15px;
          row-gap: 15px;
          margin-top: 15px;
        }
      }

      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 2px;

        @media (max-width: $tablet--small) {
          font-size: 17px;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }

      &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        img {
          object-position: center;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &:after {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.7;
          background: linear-gradient(313deg, var(--background_heavy) 10%, var(--body_color) 90%);
        }
      }

      &:after {
        display: block;
        content: "";
        padding-bottom: 100%;
      }

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 10px 20px -10px $shadow-color;

        @media (max-width: $tablet) {
          transform: translateY(0);
          box-shadow: none;
        }
      }
    }

    .filter-bar {
      margin-bottom: $spacing--tiny;
    }
  }

  @keyframes slide-text-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
